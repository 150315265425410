* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html, #root {
  height: 100%;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}
